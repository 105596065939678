<template>
  <template v-if="full">
    <div v-if="message && visible" class="alert alert-primary" role="alert">
      <div class="max-w-screen-xl mx-auto">
        <div class="flex items-center justify-between mb-1">
          <span class="flex items-center gap-1">
            <IconSvg icon="status_yes" classes="text-white fill-white" />
            <span class="font-bold text-xl/6">{{ title }}</span>
          </span>

          <span class="cursor-pointer" @click="hideAlert">
            <IconSvg icon="close" classes="text-white fill-white" />
          </span>
        </div>
        <div class="text-white">{{ content }}</div>
      </div>
    </div>
  </template>
  <template v-else>
    <div v-if="message" class="alert alert-primary" role="alert">
      <span>{{ message }}</span>
    </div>
  </template>
</template>
<script setup>
import { computed, ref } from "vue";
import { usePage } from "@inertiajs/vue3";

const props = defineProps({
  message: String,
  full: Boolean,
});
const flash = computed(() => usePage().props.flash);

const message = computed(() => {
  if (props.message) return props.message;
  if (flash.value?.notice) return flash.value.notice;

  return undefined;
});

const title = computed(() => message.value.split('<br>')[0])
const content = computed(() => message.value.split('<br>')[1])

const visible = ref(true);
const hideAlert = () => {
  visible.value = false;
};
</script>
