import I18n from '@commons/i18n'

// https://vuejs.org/guide/reusability/custom-directives.html
export default {
  // eslint-disable-next-line no-unused-vars
  install (Vue, options) {
    Vue.config.globalProperties.$t = (scope, options) => I18n.t(scope, options)

    Vue.directive('t', {
      created: (el, binding) => {
        // el.innerText = I18n.t(binding.value)
      },
      beforeMount: (el, binding) => {
        // el.innerText = I18n.t(binding.value)
      },
      mounted: (el, binding) => {
        el.innerText = I18n.t(binding.value)
      },
      beforeUpdate: (el, binding) => {
        // el.innerText = I18n.t(binding.value)
      },
      updated: (el, binding) => {
        // el.innerText = I18n.t(binding.value)
      },
      beforeUnmount: (el, binding) => {
      },
      unmounted: (el, binding) => {
      },
    })
  }
}
