<template>
  <HeadTitle :title="title"></HeadTitle>
  <div class="flex flex-col justify-between h-screen guest-layout">
    <div class="main">
      <div class="flex items-center justify-around h-screen">
        <div>
          <h1 class="text-4xl font-bold text-white" v-t="'site'"></h1>
        </div>
        <div>
          <div class="guest-card text-gray-60">
            <div class="card-body">
              <div class="mt-5 sm:mx-auto sm:w-full sm:max-w-sm"></div>
              <slot></slot>
            </div>
          </div>

          <div class="mt-5">
            <FlashNotice full></FlashNotice>
            <FlashAlert full></FlashAlert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import HeadTitle from "@layouts/commons/head_title.vue";

const page = usePage();
const title = computed(() => page.props.title);
</script>

<style lang="scss">
.guest-layout {
  .guest-card {
    @apply flex flex-col justify-center shadow-lg min-w-[400px];

    .card-body {
      @apply p-10 rounded-lg;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }
}
</style>
