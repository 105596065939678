<template>
  <nav class="flex justify-between items-center" aria-label="Table navigation">
    <ul class="border-r dark:border-gray-700">
      <li>
        <template v-if="paginate.prevPage">
          <Link :href="prevPageUrl" class="flex items-center justify-center h-full p-5 ml-0 text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            <span class="sr-only">Previous</span>
            <i class="fa-solid fa-angle-left fa-fw"></i>
          </Link>
        </template>
        <template v-else>
          <button type="button" disabled class="flex items-center justify-center h-full p-5 ml-0 text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            <span class="sr-only">Previous</span>
            <i class="fa-solid fa-angle-left fa-fw"></i>
          </button>
        </template>
      </li>
    </ul>
    <ul class="inline-flex items-stretch">
      <li v-for="(link, index) in pagination" :key="index" :class="{ 'border-b-2 border-blue-500': link.active}">
        <template v-if="link.url">
          <template v-if="link.active">
            <button type="button" disabled class="font-bold flex items-center justify-center text-sm pt-5 pb-4 px-5 leading-tight text-gray-800 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
              {{ link.label }}
            </button>
          </template>
          <template v-else>
            <Link :href="link.url" :class="{ 'font-bold text-gray-800 dark:text-gray-100': link.active }" class="flex items-center justify-center text-sm p-5 leading-tight text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
              {{ link.label }}
            </Link>
          </template>
        </template>
        <template v-else>
          <button type="button" disabled class="flex items-center justify-center text-sm p-5 leading-tight text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{{ link.label }}</button>
        </template>
      </li>
    </ul>

    <ul class="border-l dark:border-gray-700">
      <li>
        <template v-if="paginate.nextPage">
          <Link :href="nextPageUrl" class="flex items-center justify-center h-full p-5 leading-tight text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            <span class="sr-only">Next</span>
            <i class="fa-solid fa-angle-right fa-fw"></i>
          </Link>
        </template>
        <template v-else>
          <button type="button" disabled class="flex items-center justify-center h-full p-5 leading-tight text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            <span class="sr-only">Next</span>
            <i class="fa-solid fa-angle-right fa-fw"></i>
          </button>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { computed, onMounted, onBeforeUnmount } from 'vue'
import { router } from '@inertiajs/vue3'
import { useHelper } from './helpers'

const props = defineProps({
  paginate: { type: Object, required: true },
  serverUrl: { type: String },
  pageAround: { type: Number, default: 1 },
  ellipsis: {
    type: Object,
    default: {
      label: '...',
      active: false,
      url: null
    }
  }
})

const helper = useHelper()
const serverUrl = computed(() => props.serverUrl || window.location.pathname)
const pagination = computed(() => {
  const urlParams = new URLSearchParams(helper.urlSearch.value)
  const delta = props.pageAround

  const range = []
  for (
    let i = Math.max(2, props.paginate.currentPage - delta);
    i <= Math.min(props.paginate.totalPages - 1, props.paginate.currentPage + delta);
    i++
  ) {
    urlParams.set('page', i)
    range.push({
      label: i,
      active: props.paginate.currentPage === i,
      url: `${serverUrl.value}?${urlParams.toString()}`
    })
  }

  if (props.paginate.currentPage - delta > 2) {
    range.unshift(props.ellipsis)
  }
  if (props.paginate.currentPage + delta < props.paginate.totalPages - 1) {
    range.push(props.ellipsis)
  }

  const isFirstPage = props.paginate.currentPage === 1
  const isTotalPages = props.paginate.currentPage === props.paginate.totalPages

  urlParams.delete('page')
  range.unshift({
    label: 1,
    active: isFirstPage,
    url: `${serverUrl.value}?${urlParams.toString()}`
  });

  if (props.paginate.totalPages > 1) {
    urlParams.set('page', props.paginate.totalPages)
    range.push({
      label: props.paginate.totalPages,
      active: isTotalPages,
      url: `${serverUrl.value}?${urlParams.toString()}`
    });
  }

  return range
})

const prevPageUrl = computed(() => {
  if (props.paginate.prevPage === null) return

  const urlParams = new URLSearchParams(helper.urlSearch.value)
  urlParams.set('page', props.paginate.prevPage)
  if (props.paginate.prevPage === 1) urlParams.delete('page')

  return `${serverUrl.value}?${urlParams.toString()}`
})

const nextPageUrl = computed(() => {
  if (props.paginate.nextPage === null) return

  const urlParams = new URLSearchParams(helper.urlSearch.value)
  urlParams.set('page', props.paginate.nextPage)

  return `${serverUrl.value}?${urlParams.toString()}`
})

const onKeyupEvent = (event) => {
  if (localStorage.getItem('enableKeyboard') && localStorage.getItem('enableKeyboard') !== 'true') return

  if ((event.keyCode === 65 || event.keyCode === 37) && props.paginate.prevPage) {
    router.get(prevPageUrl.value)
  } else if ((event.keyCode === 70 || event.keyCode === 39) && props.paginate.nextPage) {
    router.get(nextPageUrl.value)
  }
}

onMounted (() => {
  addEventListener('keyup', onKeyupEvent)
})

onBeforeUnmount (() => {
  removeEventListener('keyup', onKeyupEvent)
})
</script>
