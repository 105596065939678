import { I18n } from 'i18n-js'
import en from '@locales/i18n/en.json'
import zhTW from '@locales/i18n/zh-TW.json'
import zhCN from '@locales/i18n/zh-CN.json'
const locales = {
  en,
  'zh-TW': zhTW,
  'zh-CN': zhCN
}

const i18n = new I18n()
i18n.locale = gon.locale
i18n.store(locales[gon.locale])

export default i18n
