<template>
  <!-- Modal toggle -->
  <a :data-modal-target="modalId" :data-modal-toggle="modalId" href="javascript:;"
     :class="$attrs.class">
    <slot name="button">
      {{ buttonText || $t('helpers.delete') }}
    </slot>
  </a>

  <!-- Main modal -->
  <Teleport to="body">
    <div :id="modalId" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div class="relative w-full max-w-md max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              {{ title }}
            </h3>
            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" :data-modal-hide="modalId">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>

          <!-- Modal alert -->
          <div>
            <slot name="alert"></slot>
          </div>

          <!-- Modal body -->
          <div class="p-4 md:p-5 space-y-4">
            <slot></slot>
          </div>

          <!-- Modal footer -->
          <template v-if="withConfirmText">
            <!-- confirm text footer -->
            <div class="px-4 py-4 border-t border-gray-200">
              <p class="mb-2">
                <span v-t="'helpers.modal.confirm_enter1'"></span>
                <span class="badge py-1 px-2 mx-1">{{ modalConfirmText }}</span>
                <span v-t="'helpers.modal.confirm_enter2'"></span>
              </p>
              <input v-model="confirmText" type="text" class="input mb-3">

              <button @click="onSubmit" :disabled="confirmText !== modalConfirmText" :data-modal-hide="modalId" type="button" :class="modalSubmitClass" class="btn w-full">
                {{ modalSubmitText || $t('helpers.confirm') }}
              </button>
            </div>
          </template>

          <template v-else>
            <!-- normal modal footer -->
            <div class="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button @click="onSubmit" :data-modal-hide="modalId" type="button" :class="modalSubmitClass" class="btn">
                {{ modalSubmitText || $t('helpers.confirm') }}
              </button>
              <button :data-modal-hide="modalId" type="button" class="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                {{ modalCancelText || $t('helpers.cancel') }}
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { onMounted, getCurrentInstance, ref } from 'vue'
// import { initModals } from 'flowbite'

const instance = getCurrentInstance()
const modalId = `default-modal-${instance.uid}`

const props = defineProps({
  buttonText: { type: String },
  modalTitle: { type: String },
  modalSubmitClass: { type: String },
  modalSubmitText: { type: String },
  modalCancelText: { type: String },
  withConfirmText: { type: Boolean, default: false },
  modalConfirmText: { type: String },
})

// emit
const emit = defineEmits(['submit'])

const title = props.modalTitle || props.text

const confirmText = ref('')

const onSubmit = () => {
  emit('submit')
}

onMounted(() => {
  initModals()
})
</script>
