<template>
  <!-- html head title, meta -->
  <HeadTitle></HeadTitle>

  <div class="flex flex-col justify-between h-screen dark:bg-gray-950">
    <div class="main">
      <template v-if="currentUser">
        <Header></Header>

        <FlashNotice full></FlashNotice>
        <FlashAlert full></FlashAlert>
      </template>
      <slot></slot>
    </div>

    <Footer></Footer>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import HeadTitle from "@layouts/commons/head_title.vue";
import Header from "@layouts/commons/header.vue";
import Footer from "@layouts/commons/footer.vue";

const currentUser = computed(() => usePage().props.current_user);
</script>
