import { computed } from 'vue'
import { usePage } from '@inertiajs/vue3'

const urlSearch = computed(() => {
  const url = usePage().url
  if (url.includes('?')) return url.substring(url.indexOf('?'))
  return ''
})

const urlParams = computed(() => new URLSearchParams(urlSearch.value))

// methods delay
const debounce = (func, delay) => {
  let timer = null
  return () => {
    const context = this
    // const args = arguments
    clearTimeout(timer)
    timer = setTimeout(() => {
      // func.apply(context, args)
      func.apply(context)
    }, delay)
  }
}

const useHelper = () => {
  return { urlSearch, urlParams, debounce }
}

export { useHelper }
