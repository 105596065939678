<template>
  <th @click="toggleSort" scope="col" class="px-4 py-3" :class="thClass">
    <template v-if="selectEnable">
      <select v-model="selectedOption" @change="onChange" name="options" class="select">
        <option :value="null">{{ text }}</option>
        <option v-for="option in selectOptions" :value="option.id">{{ option.name }}</option>
      </select>
    </template>
    <template v-else>
      <slot>{{ text }}</slot>

      <template v-if="showOrderIcon">
        <span class="ms-2">
          <i v-if="sortDirAsc" class="fas fa-sort-amount-up"></i>
          <i v-if="sortDirDesc" class="fas fa-sort-amount-down"></i>
        </span>
      </template>
    </template>
  </th>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { router } from '@inertiajs/vue3'
import { useHelper } from './helpers'

const props = defineProps({
  id: { type: String },
  text: { type: String },
  selectOptions: { type: Array, default: () => [] },
  disableOrder: { type: Boolean, default: false },
  defaultOrderColumn: { type: Boolean, default: false },
  sortDir: { type: String, default: 'desc' },
})

if (props.disableOrder && props.defaultOrderColumn) throw new Error('[disableOrder], [defaultOrderColumn] cannot be used together.');

const helper = useHelper()
const currentOrderColumn = ref(helper.urlParams.value.get('orderColumn'))
const currentOrderDir = ref(helper.urlParams.value.get('orderDir') || props.sortDir)
const selectEnable = props.selectOptions && props.selectOptions.length > 0
const enabledOrder = !selectEnable && !!props.id && !props.disableOrder
const selectedOption = ref(helper.urlParams.value.get(props.id))

const refreshData = () => {
  const url = window.location.pathname

  router.get(url, filterParams.value, { preserveState: true })
}

const toggleSort = () => {
  if (!enabledOrder) return

  if (currentOrderDir.value === 'asc') {
    currentOrderDir.value = 'desc'
  } else {
    currentOrderDir.value = 'asc'
  }

  refreshData()
}
const sortDirAsc = computed(() => {
  return currentOrderDir.value === 'asc'
})
const sortDirDesc = computed(() => {
  return currentOrderDir.value === 'desc'
})
const thClass = computed(() => {
  return { 'cursor-pointer': enabledOrder }
})

const filterParams = computed(() => {
  const params = Object.fromEntries(helper.urlParams.value)

  if (!selectEnable && !props.disableOrder) {
    params.orderColumn = props.id
    params.orderDir = currentOrderDir.value

    if (props.defaultOrderColumn) params.orderColumn = undefined
    if (props.sortDir === currentOrderDir.value) params.orderDir = undefined
  }

  if (selectedOption.value) {
    params[props.id] = selectedOption.value
    params.page = 1
  } else {
    params[props.id] = undefined
  }

  return params
})

const showOrderIcon = computed(() => {
  return enabledOrder && ((props.defaultOrderColumn && !currentOrderColumn.value) || currentOrderColumn.value === props.id)
})

const onChange = () => {
  refreshData()
}

watch(helper.urlSearch, () => {
  currentOrderColumn.value = helper.urlParams.value.get('orderColumn')
  currentOrderDir.value = helper.urlParams.value.get('orderDir') || props.sortDir
})
</script>
