<template>
  <form ref="oauthForm" method="post" action="/users/auth/okta">
    <input
      type="hidden"
      name="authenticity_token"
      :value="authenticity_token"
    />
    <h3> {{ $t("processing") }} </h3>
  </form>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import { ref, onMounted } from "vue";

const props = defineProps({
  authenticity_token: String
});

const oauthForm = ref(null);

onMounted(() => {
  oauthForm.value.submit();
});
</script>
