<template>
  <Head :title="headTitle">
    <slot />
  </Head>
</template>

<script setup>
import { Head } from '@inertiajs/vue3'

const props = defineProps({
  title: String
})

const defaultTitle = gon.head_title
const headTitle = props.title ? `${props.title} | ${defaultTitle}` : defaultTitle
</script>
