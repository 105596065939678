<template>
  <div class="footer-block">
    <footer class="py-3 bg-white border-t dark:bg-gray-800 dark:border-gray-700">
        <div class="p-4 lg:px-6 md:flex md:items-center md:justify-between">
          <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © {{ currentYear }} <a v-text="defaultTitle" href="/" class="hover:underline"></a>. All Rights Reserved.
        </span>
        <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <a href="#" class="hover:underline me-4 md:me-6">About</a>
          </li>
          <li>
            <a href="#" class="hover:underline me-4 md:me-6">Privacy Policy</a>
          </li>
          <li>
            <a href="#" class="hover:underline me-4 md:me-6">Licensing</a>
          </li>
          <li>
            <a href="#" class="hover:underline">Contact</a>
          </li>
        </ul>
        </div>
    </footer>
  </div>
</template>

<script setup>
const currentYear = new Date().getFullYear()
const defaultTitle = gon.head_title
</script>
