<template>
  <!-- Start coding here -->
  <div class="datatable border bg-white dark:bg-gray-800 relative shadow sm:rounded-lg overflow-hidden dark:border-gray-700 dark:shadow-none">
    <!-- head -->
    <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
      <div class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
        <div class="flex items-center space-x-3 w-full md:w-auto">
          <select v-model="displayLength" @change="onDisplayLength" class="select">
            <!-- <option value="1">1</option> -->
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <div v-if="!disableSearch" class="w-full md:w-1/3">
        <label v-t="'helpers.search'" for="simple-search" class="sr-only"></label>
        <div class="relative w-full">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
            </svg>
          </div>
          <input v-model="search" @input="onSearch" type="text" id="simple-search"
                 class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                :placeholder="searchPlaceholder">
        </div>
      </div>
    </div>

    <!-- content -->
    <div class="overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <slot name="thead"></slot>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(resource, index) in resources" :key="index" class="border-b hover:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-900">
            <slot name="tbody-tr" :resource="resource"></slot>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- paginate -->
    <slot name="paginate">
      <Paginate :paginate="paginate"></Paginate>
    </slot>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { router } from '@inertiajs/vue3'
import Paginate from '@components/datatable/paginate.vue'
import { useHelper } from './helpers'

const props = defineProps({
  resources: { type: Array, required: true, default: [] },
  disableSearch: { type: Boolean, default: false },
  searchPlaceholder: { type: String, default: 'search...' },
  paginate: { type: Object, required: true }
})

// params
const helper = useHelper()
const search = ref(helper.urlParams.value.get('search') || undefined)
const displayLength = ref(helper.urlParams.value.get('pre') || 10)

const onRefreshData = helper.debounce(() => {
  const url = window.location.pathname

  router.get(url, filterParams.value, { preserveState: true })
}, 200)

const onSearch = () => {
  onRefreshData()
}
const onDisplayLength = () => {
  onRefreshData()
}

// computed
const filterParams = computed(() => {
  const params = Object.fromEntries(helper.urlParams.value)

  // search
  if (search.value) {
    params.search = search.value
  } else {
    params.search = undefined
  }

  // displayLength
  if (Number(params.pre) !== Number(displayLength.value)) {
    params.page = undefined
  }
  if (Number(displayLength.value) === 10) {
    params.pre = undefined
  } else {
    params.pre = displayLength.value
  }

  return params
})
</script>
