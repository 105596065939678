<template>
  <div class="page-header">
    <div class="flex py-4">
      <h1 v-if="title" class="flex-initial text-3xl font-extrabold leading-tight text-gray-900 lg:text-4xl dark:text-white">{{ title }}</h1>

      <div v-if="hasFunctional" class="flex-auto space-x-2 text-end">
        <slot name="functional"></slot>
      </div>
    </div>

    <!-- Breadcrumb -->
    <nav v-if="hasBreadcrumb" class="flex" aria-label="Breadcrumb">
      <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        <PageHeaderItem href="/" skipArrow>
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
          </svg>
        </PageHeaderItem>

        <slot name="breadcrumb"></slot>
      </ol>
    </nav>
  </div>

  <Head v-if="headerTitle" :title="headerTitle"></Head>
</template>

<script setup>
import { computed, useSlots } from 'vue'
import { Head } from '@inertiajs/vue3'

const props = defineProps({ title: { type: String } })

const slots = useSlots()

const hasBreadcrumb = computed(() => {
  return !!slots.breadcrumb
})

const hasFunctional = computed(() => {
  return !!slots.functional
})

const headerTitle = computed(() => {
  const defaultTitle = 'Leads Analysis'

  return props.title ? `${props.title} | ${defaultTitle}` : defaultTitle
})
</script>
