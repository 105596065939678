<template>
  <span v-html="svg" class="icon" :class="classList"></span>
</template>

<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  icon: {
    type: String,
    required: true
  },
  class: {
    type: String
  },
})

const svg = ref('')

const importSvg = async () => {
  try {
    const module = await import(`@images/icon/${props.icon}.svg?raw`)
    svg.value = module.default
  } catch (error) {
    console.error(`Failed to load SVG: ${props.icon}`, error)
  }
}

importSvg()

const classList = computed(() => props.class)
</script>
