<template>
  <form @submit.prevent="formSubmit" class="space-y-6">
    <div>
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2
          class="mb-4 text-2xl font-bold leading-9 tracking-tight text-left"
        >
          {{ $t("devise.passwords.new.forgot_your_password") }}
          <Link
            class="text-sm btn-link"
            href="/users/sign_in"
            preserve-scroll
          >
            回登入頁
          </Link>
        </h2>
        <p class="mb-4" v-html="$t('devise.passwords.new.message')"></p>
      </div>

      <div class="mt-2">
        <label for="email" class="relative block">
          <IconSvg icon="account" class="w-4 h-4 with-input" />
          <input
            v-model="form.user.email"
            id="email"
            type="email"
            autocomplete="email"
            required
            :placeholder="$t('activerecord.attributes.user.email')"
            class="input with-icon"
          />
        </label>
      </div>

      <div class="mt-4">
        <button
          :disabled="!canSubmit"
          type="submit"
          class="w-full btn"
          :class="{
            'btn-primary': canSubmit,
          }"
        >
          {{ $t("devise.passwords.new.send_me_reset_password_instructions") }}
          <span v-if="form.errors.count_down">{{
            $t("devise.passwords.new.throttle_count_down", {
              count_down: form.errors.count_down,
            })
          }}</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { useForm, router } from "@inertiajs/vue3";
import { computed, watch } from "vue";

const props = defineProps({
  email: String,
});

const form = useForm({
  user: {
    email: props.email,
  },
  errors: Object,
});

watch(
  () => form.errors.count_down,
  (newValue, oldValue) => {
    if (newValue > 0) {
      setTimeout(() => {
        form.errors.count_down--;
      }, 1000);
    }
  }
);

const validateEmail = () => {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
    form.user.email
  );
};

const canSubmit = computed(() => {
  return (
    validateEmail() &&
    (typeof form.errors.count_down == "undefined" ||
      form.errors.count_down === 0)
  );
});

router.on("error", () => {
  if (window.location.pathname === "/users/password") {
    router.visit("/users/password/new", {
      data: {
        email: props.email,
      },
      preserveState: true,
      preserveScroll: true,
      replace: true,
    });
  }
});

const formSubmit = () => {
  form.post("/users/password");
};
</script>
