<template>
  <Oauth v-if="isOauth" :authenticity_token="authenticity_token" />
  <form v-else @submit.prevent="formSubmit" class="space-y-6">
    <div v-if="!showPasswordInput">
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2
          class="mb-10 text-2xl font-bold leading-9 tracking-tight text-left"
        >
          {{ $t("devise.sessions.new.sign_in") }}
        </h2>
      </div>
      <div class="mt-2">
        <label for="email" class="relative block">
          <IconSvg icon="account" class="w-4 h-4 with-input" />
          <input
            v-model="form.user.email"
            id="email"
            type="email"
            autocomplete="email"
            required
            :placeholder="$t('activerecord.attributes.user.email')"
            class="input with-icon"
            :class="{
              error: form.errors.email,
            }"
          />
        </label>
        <p class="text-right text-warning" v-if="form.errors">
          {{ form.errors.email }}
        </p>
      </div>

      <div class="mt-4">
        <Link
          href="/check_email"
          method="post"
          :data="{ email: form.user.email }"
          as="button"
          preserve-scroll
          :disabled="!form.user.email"
          @success="handleEmailCheck"
          @error="handleError"
          class="w-full btn"
          :class="{
            'btn-primary': form.user.email,
          }"
        >
          {{ isChecking ? $t("processing") : $t("next") }}
        </Link>
      </div>
    </div>

    <div v-else>
      <div
        class="mb-4 text-left sm:mx-auto sm:w-full sm:max-w-sm"
      >
        <h2 class="text-2xl font-bold leading-9 tracking-tight">
          {{ $t("greeting") }}
        </h2>

        <h4>{{ form.user.email }}</h4>
        <Link class="text-sm btn-link" href="/users/sign_in" preserve-scroll>
          {{ $t("devise.sessions.new.change_account") }}
        </Link>
      </div>

      <div class="relative mt-2">
        <label for="password" class="relative block">
          <IconSvg icon="lock" class="w-4 h-4 with-input" />
          <input
            v-model="form.user.password"
            id="password"
            type="password"
            autocomplete="current-password"
            required
            :placeholder="$t('activerecord.attributes.user.password')"
            class="input with-icon"
          />
        </label>
        <IconSvg
          icon="showtext"
          class="w-4 h-4 cursor-pointer with-input input-end"
          v-if="isPasswordVisible"
          @click="togglePasswordVisibility"
        />
        <IconSvg
          icon="hidetext"
          class="w-4 h-4 cursor-pointer with-input input-end"
          v-else
          @click="togglePasswordVisibility"
        />
      </div>
      <p class="text-right">
        <Link
          class="text-sm btn-link"
          href="/users/password/new"
          :data="{ email: form.user.email }"
          preserve-scroll
        >
          {{ $t("devise.passwords.new.forgot_your_password") }}
        </Link>
      </p>
      <div class="mt-4">
        <button
          type="submit"
          :disabled="!form.user.password"
          class="w-full btn"
          :class="{
            'btn-primary': form.user.password,
          }"
        >
          {{ $t("devise.sessions.new.sign_in") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { useForm, router } from "@inertiajs/vue3";
import { ref } from "vue";
import Oauth from "./oauth.vue";

const form = useForm({
  user: {
    email: "",
    password: "",
  },
});

const showPasswordInput = ref(false);
const isChecking = ref(false);
const isPasswordVisible = ref(false);
const submitPath = ref("/users/sign_in");
const isOauth = ref(false);
const authenticity_token = ref("");
const formSubmit = () => {
  form.post(submitPath.value);
};

const togglePasswordVisibility = () => {
  isPasswordVisible.value = !isPasswordVisible.value;

  if (isPasswordVisible.value) {
    document.getElementById("password").type = "text";
  } else {
    document.getElementById("password").type = "password";
  }
};

const handleEmailCheck = (page) => {
  isChecking.value = true;
  // 維持 sign_in 頁面
  router.visit("/users/sign_in", {
    preserveState: true,
    preserveScroll: true,
    replace: true,
  });

  if (page.props.is_sso) {
    isOauth.value = true;
    authenticity_token.value = page.props.authenticity_token;
  }

  showPasswordInput.value = page.props.show_password_input;
  isChecking.value = false;
};

const handleError = (errors) => {
  isChecking.value = false;
  form.errors = errors;
  router.visit("/users/sign_in", {
    preserveState: true,
    preserveScroll: true,
    replace: true,
  });
};
</script>
